<template>
  <!-- 剪裁图片组件 -->
  <!-- closeable -->
  <div
    class="flex-column-center height100"
    v-show="showCropper"
  >
    <vue-cropper
      ref="cropper"
      :img="option.img"
      :outputSize="option.outputSize"
      :outputType="option.outputType"
      :info="option.info"
      :full="option.full"
      :autoCropWidth="option.autoCropWidth"
      :autoCropHeight="option.autoCropHeight"
      :canMove="option.canMove"
      :canMoveBox="option.canMoveBox"
      :original="option.original"
      :autoCrop="option.autoCrop"
      :fixed="option.fixed"
      :fixedNumber="option.fixedNumber"
      :centerBox="option.centerBox"
      :infoTrue="option.infoTrue"
      :fixedBox="option.fixedBox"
      :high="option.high"
      :mode="option.mode"
    ></vue-cropper>
    <div class="do-things">
      <span @click="cancelCropper">取消</span>
      <span
        @click="rotateImage"
        class="font18"
      >
        <van-icon name="replay" /></span>
      <span @click="getCropBlob">确定</span>
    </div>
  </div>
</template>
<script>
import { VueCropper } from "vue-cropper";
import { setPicHead } from "@/fetch/api/login.js"
export default {
  name: "UserProfile",
  props: {
    showCropper: {
      type: Boolean,
      default: false
    },
    option: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VueCropper
  },
  data() {
    return {
      broker_id: localStorage.getItem('broker_id'),
    };
  },
  methods: {
    // 确认剪裁并上传图片
    getCropBlob() {
      this.$refs.cropper.getCropBlob(data => {
        // 获取截图的blob数据
        let aTime = new Date().getTime(); //取时间戳，给文件命名
        let fileName = aTime + "." + data.type.substr(6); //给文件名加后缀
        let file = new window.File([data], fileName, { type: data.type }); //blob转file
        let formData = new FormData();
        formData.append('file', file);
        formData.append('what', 'pic');
        formData.append('broker_id', this.broker_id)
        setPicHead(this.broker_id,formData).then(res=>{
          if(res.code == 200){
            this.$emit('changePicture',res.result.head_portrait)
          }else{
            this.$toast('上传失败，请稍后重试')
          }
        })
      });
    },
    // 旋转图片
    rotateImage() {
      this.$refs.cropper.rotateRight();
    },
    // 取消截图上传头像
    cancelCropper() {
      this.$emit("handleCropper");
    }
  }
};
</script>

<style lang="scss" scoped>
.height100 {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
}
.flex-column-center {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
.vue-cropper {
  background: #000;
  .cropper-view-box {
    outline: 1px solid #fff !important;
    outline-color: #fff !important;
  }
}
.do-things {
  width: 100%;
  height: 1rem;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0.4rem;
  span {
    display: inline-block;
    width: 1rem;
    text-align: center;
    font-size: 0.48rem;
    color: #fff;
  }
}
</style>