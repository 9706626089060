<template>
  <div
    class="personal-home"
    v-if="userInfo"
  >
    <heads title="个人中心"></heads>
    <van-uploader
      class="upload-wrapper"
      :max-size="3 * 1024 * 1024"
      :before-read="beforeRead"
      @oversize="onOversize"
    >
      <div class="avatar">
        <img
          :src="userInfo.head_portrait ? userInfo.head_portrait : require('@/assets/images/chat_image_head_oneself.png')"
          class="avatar-img"
        >
        <p>更换头像</p>
        <img
          :src="require('@/assets/images/personal_center_right.png')"
          class="right-icon"
        >
      </div>
    </van-uploader>
    <div class="person-content">
      <dl @click="changeNameFlag(true)">
        <dt>昵称</dt>
        <dd>
          {{nameFlag ? '' : fileName}}
          <van-field
            v-model="fileName"
            v-show="nameFlag"
            maxlength='8'
            @input="changeName"
            @blur="changeNameFlag(false)"
          />
          <img
            :src="require('@/assets/images/personal_center_right.png')"
            class="right-icon"
          >
        </dd>
      </dl>
      <dl @click="showSexPicker">
        <dt>性别</dt>
        <dd>
          {{defaultSex==0?'男':''}}
          {{defaultSex==1?'女':''}}
          <img
            :src="require('@/assets/images/personal_center_right.png')"
            class="right-icon"
          >
        </dd>
      </dl>
      <!-- <dl @click="$router.push('/personal/agentVerification')">
        <dt>代理人身份验证</dt>
        <dd :class="`go-verify ${userInfo.verified == 0 ? '' : 'black'}`">
          {{userInfo.verified == 0 ? '去验证' : '已认证'}}
          <img
            :src="require('@/assets/images/personal_center_right.png')"
            class="right-icon"
          >
        </dd>
      </dl> -->
      <dl @click="$router.push('/personal/changePhone/change')">
        <dt>{{userInfo.mobile ? '修改手机号' : '绑定手机号'}}</dt>
        <dd :class="`${userInfo.mobile?'':'go-verify'}`">
          {{userInfo.mobile || '去绑定'}}
          <img
            :src="require('@/assets/images/personal_center_right.png')"
            class="right-icon"
          >
        </dd>
      </dl>
      <!-- <dl @click="$router.push('/personal/changePassword')">
        <dt>修改密码</dt>
        <dd>
          <img
            :src="require('@/assets/images/personal_center_right.png')"
            class="right-icon"
          >
        </dd>
      </dl> -->
    </div>
    <!-- <div
      class="back-login"
      @click="onLogOut"
    >退出登录</div> -->

    <!-- 性别picker start -->
    <van-popup
      v-model="sexPicker"
      round
      position="bottom"
    >
      <van-picker
        show-toolbar
        title="性别"
        :columns="sexList"
        :default-index="defaultSex"
        @cancel="sexPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <!-- 性别picker end -->
    <cropper
      :showCropper="showCropper"
      :option="option"
      @handleCropper="handleCropper"
      @changePicture="changePicture"
    />
  </div>

</template>

<script>
import Header from "@/components/Header";
import Cropper from "@/components/Cropper";
import { Local } from "@/utils/local.js";
import { mapState, mapMutations } from "vuex";
// getUserInfo
import { changeUserInfo } from "@/fetch/api/login";
export default {
  components: {
    heads: Header,
    cropper: Cropper,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      defaultSex: (state) => state.user.userInfo.gender,
      name: (state) => state.user.userInfo.nick_name,
    }),
  },
  data() {
    return {
      sexList: ["男", "女"],
      sexPicker: false,
      nameFlag: false,
      showCropper: false, // 截图弹窗遮罩默认隐藏
      imageAccept: "/jpg,/png,/jpeg",
      imageFileName: "",
      fullName: "",
      // 记录上一次名字
      oldName: "",
      // name: '未设置',
      // defaultSex : 0,
      option: {
        img: "",
        outputSize: 0.8,
        info: false, // 裁剪框的大小信息
        outputType: "jpeg", // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: window.innerWidth - 100 + "px", // 默认生成截图框宽度
        autoCropHeight: window.innerWidth - 100 + "px", // 默认生成截图框高度
        high: true, // 是否按照设备的dpr 输出等比例图片
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        mode: "100% auto", // 图片默认渲染方式
      },
      broker_id: localStorage.getItem("broker_id"),
    };
  },
  methods: {
    ...mapMutations(["CHANGE_USEFINFO"]),
    changePicture(picUrl) {
      this.userInfo.head_portrait = picUrl;
      this.showCropper = false;
    },
    showSexPicker() {
      this.sexPicker = true;
    },
    onConfirm(value) {
      let sex;
      switch (value) {
        case "男":
          sex = 0;
          break;
        case "女":
          sex = 1;
          break;
        default:
          sex = 0;
          break;
      }
      let params = { gender: sex, choice: 1 };
      changeUserInfo(this.broker_id, params).then((res) => {
        if (res.code == 200) {
          this.CHANGE_USEFINFO({ type: "sex", data: res.result.gender });
        }
      });
      // this.defaultSex = sex;
      this.sexPicker = false;
    },
    changeName(value) {
      let test = value.trim();
      if (test) {
        this.CHANGE_USEFINFO({ type: "name", data: value });
      } else {
        this.fileName = this.oldName;
        this.$toast("昵称为空或者为纯空格时,则不保存昵称");
      }
    },
    changeNameFlag(flag) {
      if (!flag) {
        let params = { nick_name: this.fileName, choice: 0 };
        changeUserInfo(this.broker_id, params).then((res) => {
          if (res.code == 200) {
            // this.name = res.result.nick_name
            this.CHANGE_USEFINFO({ type: "name", data: res.result.nick_name });
          }
        });
      }
      this.nameFlag = flag;
    },
    onLogOut() {
      Local.clear();
      this.$router.replace("/register-login/codeLogin");
    },
    onOversize() {
      this.$notify("图片不能大于3M");
    },
    // 获取文件后缀
    getFileSuffix(fileName) {
      return fileName.match(/\/\w+$/)[0].toLowerCase();
    },
    // 将本地图片转化为Base64，否则vue-cropper组件显示不出要本地需要剪裁的图片
    imageToBase64(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // 截图框中的图片
        this.option.img = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    // 选择图片上传前操作，调起剪裁组件
    beforeRead(file) {
      if (!this.imageAccept.includes(this.getFileSuffix(file.type))) {
        return this.$toast("请上传 jpg/png 格式图片");
      }
      this.$toast("昂");
      this.showCropper = true;
      this.imageFileName = file.name;
      // 本地图片转成base64，用于截图框显示本地图片
      this.imageToBase64(file);
    },
    handleCropper() {
      this.showCropper = false; // 隐藏切图遮罩
    },
    // getUserInfoData() {
    //   getUserInfo().then(res => {
    //     if (res.code == 200) {
    //       this.userInfo = res.result;
    //       this.defaultSex = res.result.gender;
    //       this.name = res.result.nick_name;
    //     }
    //   });
    // },
  },
  created() {
    this.fileName = this.name;
    this.oldName = this.name;
    // this.getUserInfoData();
  },
};
</script>

<style lang="scss" scoped>
.personal-home {
  // height: 100%;
  /deep/ .upload-wrapper {
    width: 100%;
    .van-uploader__input-wrapper {
      width: 100%;
    }
    .avatar {
      position: relative;
      width: 100%;
      height: 2.37rem;
      background: #ffffff;
      margin: 0.26rem 0rem;
      padding: 0.4rem;
      display: flex;
      align-items: center;
      p {
        font-size: 0.37rem;
        font-weight: 400;
        color: #4a4a4a;
        line-height: 0.53rem;
        margin-left: 0.4rem;
      }
      .avatar-img {
        width: 1.56rem;
        height: 1.56rem;
        border-radius: 50%;
      }
      .right-icon {
        position: absolute;
        right: 0.4rem;
      }
    }
  }
  .person-content {
    width: 100%;
    height: auto;
    padding: 0rem 0.4rem;
    background: #fff;
    dl {
      display: flex;
      align-items: center;
      background: #fff;
      height: 1.36rem;
      border-bottom: 0.01rem solid #eeeeee;
      justify-content: space-between;
      font-size: 0.4rem;
      dd {
        display: flex;
        align-items: center;
        font-size: 0.4rem;
        font-weight: 400;
        color: #999999;
      }
      .van-cell::after {
        border-bottom: none;
      }
      .go-verify {
        color: #3971ff;
      }
      .black {
        color: #999999;
      }
    }
  }
  .back-login {
    width: 100%;
    height: 1.28rem;
    line-height: 1.28rem;
    text-align: center;
    background: #ffffff;
    font-size: 0.4rem;
    font-weight: 400;
    color: #666666;
    margin-top: 0.53rem;
  }
  .right-icon {
    width: 0.64rem;
    height: 0.64rem;
  }
}
</style>
